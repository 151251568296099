import React from "react"
import { graphql, Link } from "gatsby"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import { greyDarker, containerFixed } from "../../constants/cssVariables"
import Section from "../../components/Section/Section"
import PageHeader from "../../components/PageHeader/PageHeader"
import SocialShare from "../../components/SocialShare/SocialShare"
import branch from "../../assets/vector/vector-branch.svg"
import simpleSharePageImage from "../../assets/vector/vector-developer.svg"
import "./SimpleSharePage.css"

export const query = graphql`
    query($slug: String!) {
        simpleShareTempData: allContentfulSimpleSharePage(
            filter: { slug: { eq: $slug } }
        ) {
            edges {
                node {
                    seoTitle
                    seoDescription
                    description {
                        json
                    }
                }
            }
        }
    }
`

const SimpleShareTemplate = ({ location, data: { simpleShareTempData } }) => {
    const { seoTitle, seoDescription } = simpleShareTempData.edges[0].node

    const H1 = ({ children }) => (
        <h1 className="SimpleSharePage-title">
            {children}
            <img
                className="SimpleSharePage-title-image"
                src={branch}
                alt=""
                role="presentation"
            />
        </h1>
    )
    const H2 = ({ children }) => (
        <h2 className="SimpleSharePage-subtitle">{children}</h2>
    )
    const Text = ({ children }) => (
        <p className="SimpleSharePage-text">{children}</p>
    )

    const UL = ({ children }) => (
        <ul className="SimpleSharePage-list">{children}</ul>
    )

    const OL = ({ children }) => (
        <ol className="SimpleSharePage-list">{children}</ol>
    )

    const A = ({ node, children }) => {
        return (
            <a href={children.data.uri} className="SimpleSharePage-link">
                {children.content[0].value}
            </a>
        )
    }

    const Image = ({ children }) => (
        <img
            src={children.data.target.fields.file["en-US"].url}
            alt={children.data.target.fields.title["en-US"]}
            className="SimpleSharePage-image"
        />
    )

    const options = {
        renderNode: {
            [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
            [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [BLOCKS.UL_LIST]: (node, children) => <UL>{children}</UL>,
            [BLOCKS.OL_LIST]: (node, children) => <OL>{children}</OL>,
            [INLINES.HYPERLINK]: (node, children) => <A>{node}</A>,
            [BLOCKS.EMBEDDED_ASSET]: (node, children) => <Image>{node}</Image>,
        },
    }

    return (
        <Layout location={location}>
            <SEO title={seoTitle} description={seoDescription} />
            <Section bgColor={greyDarker} container={containerFixed}>
                <div className="SimpleSharePage">
                    <PageHeader PageHeaderTitle={"Back to Homepage"} />
                    <div className="SimpleSharePage-inner">
                        <div className="SimpleSharePage-col-wide">
                            {documentToReactComponents(
                                simpleShareTempData.edges[0].node.description
                                    .json,
                                options
                            )}
                        </div>
                        <div className="SimpleSharePage-col-narrow">
                            <img
                                src={simpleSharePageImage}
                                alt=""
                                role="presentation"
                                className="SimpleSharePage-image"
                            />
                        </div>
                    </div>
                    <div className="SimpleSharePage-bottom">
                        <Link
                            to={`/#contactForm`}
                            state={{ position: seoTitle }}
                            className="SimpleSharePage-link"
                        >
                            Apply for Position
                        </Link>
                        <SocialShare location={location} />
                    </div>
                </div>
            </Section>
        </Layout>
    )
}

export default SimpleShareTemplate
